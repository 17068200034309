import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StructuredText } from 'react-datocms';
import { PageWrapper } from '../components/PageWrapper';
import { Navigator } from '../components/Navigator';

import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import FooterCta from '../components/FooterCta';

const ServicesTemplate = ({
    data: {
        datoCmsServiceRecord: {
            seo,
            title,
            subtitle,
            heroImage,
            text,
            impressionHeader,
            detailImages,
            catalogHeader,
            catalogs,
        },
        datoCmsCatalogspage: {
            id,
            catalogspageLinkTitle,
        },
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.image?.seoImageUrl}
    >
        <div className='ServiceNavBackground'></div>
        <div className='ServiceWrapper'>
            <div className='ServiceContainerHero'>
                <div className='ServiceHeroTextContainer'>
                    <h1 className='ServiceTitle'>{title}</h1>
                    <p className='ServiceSubtitle' >{subtitle}</p>
                </div>
                <div className='ServiceHeroImageFilter'></div>
                <GatsbyImage image={heroImage.gatsbyImageData} alt={heroImage.alt} className='ServiceHeroImage' />
            </div>
            
            <div className='ServiceContainer'>
                
                <div className='ServiceText'>
                    <StructuredText data={text}  />
                </div>
                <h2 className='ServiceH2'>{impressionHeader}</h2>
                <div className='ServiceImpressions'>
                    {detailImages.map(({ id: detailImagesId, gatsbyImageData, alt }) => (
                        <div key={detailImagesId} >
                            <GatsbyImage image={gatsbyImageData} alt={alt} className='ServiceDetailImage'/>
                        </div>
                    ))}
                </div>

                <div>
                    <div className='ServiceCatalogHeaderContainer'>
                        <div className='ServiceCatalogHeaderItem'>
                            <h2 className='ServiceH2'>
                                {catalogHeader}
                            </h2>
                        </div>
                        <div className='ServiceCatalogHeaderItem'>
                            <Navigator recordId={id}>
                            <a className='ServiceCatalogHeaderLink'>
                                {catalogspageLinkTitle}
                                <ArrowLongRightIcon className="btn_wht_icon" />
                            </a>
                            </Navigator>
                        </div>
                    </div>
                    <div className='ServiceCatalogs'>
                        {catalogs.map(({ id: catalogsId, catalogThumbnail, catalogLink }) => (
                            <div key={catalogsId}>
                                <Link to={catalogLink} target="_blank">
                                <GatsbyImage image={catalogThumbnail.gatsbyImageData} alt={catalogThumbnail.alt} className='ServiceCatalogThumbnail' />
                                </Link>
                            </div>
                        ))} 
                    </div>
                </div>

            </div>
        </div>
        <FooterCta />
    </PageWrapper>
);

export default ServicesTemplate;

export const query = graphql`
    query ServiceRecordQuery($id: String!, $locale: String!) {
        datoCmsServiceRecord(originalId: { eq: $id }, locale: { eq:$locale }) {
            id
            locale
            originalId
            level
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            title
            subtitle
            heroImage {
                gatsbyImageData(
                    imgixParams: { 
                        fit: "crop"
                        w: "1800"
                    }
                )
            }
            text {
                value
            }
            impressionHeader
            detailImages {
                id: originalId
                gatsbyImageData (
                    imgixParams: { 
                        fit: "crop"
                        w: "400"
                        h: "400"
                    }
                )
            }
            catalogHeader
            catalogs {
                id: originalId
                catalogLink
                catalogThumbnail {
                    gatsbyImageData (
                        imgixParams: { 
                            fit: "crop"
                            w: "300"
                            h: "565,6"
                        }
                    )
                }
            }
        }
        datoCmsCatalogspage(locale: { eq: $locale }) {
            id: originalId
            catalogspageLinkTitle
        }
    }
`; 